<template>
    <v-container>
        <h3 class="grey--text text--darken-2"> Suivi de chantier </h3>
        <p class="subtitle-1 grey--text text--darken-2">
            Retrouvez ici les différentes photos et vidéos relatives à l'avancement du chantier pour le programme
            <v-chip color="app-green" class="ma-0 pa-1" label dark small>
                {{ selectedSale.program.title }}
            </v-chip>.
        </p>
        <p class="caption grey--text text--darken-2">
            Sélectionnez les différentes périodes de l'évolution du chantier.
        </p>

        <v-expansion-panels v-model="tab" focusable>
            <v-expansion-panel v-for="item of timeline" :key="item.year + '-' + item.month">
                <v-expansion-panel-header>
                    <v-row>
                        <v-col cols="12" lg="4" md="12" xs="12">
                            <div>
                                {{ item.monthLabel }}
                                {{ item.year }}
                            </div>
                        </v-col>
                    </v-row>

                    <v-divider class="mx-3" vertical />

                    <span class="subtitle-2 flex-grow-0">
                        {{ item.media.length }} {{ item.media.length | plural('photo', 'photos')}}
                    </span>

                    <div v-if="$vuetify.breakpoint.mdAndUp">
                        <v-avatar v-for="media of item.media.slice(0, 5)" :key="media.id" class="ml-5">
                            <v-img :src="getMediaUrl(media.id)" />
                        </v-avatar>
                    </div>
                </v-expansion-panel-header>

                <v-expansion-panel-content class="pt-5">
                    <v-row>
                        <v-col v-for="media of item.media" :key="media.id" cols="12" lg="4" md="4" xs="12">
                            <v-dialog width="80%">
                                <template v-slot:activator="{ on }">
                                    <v-img :src="getMediaUrl(media.id)" class="rounded xs-9" :aspect-ratio="16/9" v-on="on" />
                                </template>
                                <v-card>
                                    <v-img :src="getMediaUrl(media.id)" :aspect-ratio="16/9" contain width="100%" />
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>

<script>
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

import documentsMixin from '../mixins/documents';

export default {
    name: 'ProgressOnSite',

    mixins: [documentsMixin],

    data: () => ({
        tab: 0
    }),

    computed: {
        timeline() {
            const timeline = [];

            this.selectedSale.media.forEach(function (media) {
                if (media.category === 'Suivi de chantier') {
                    const date = new Date(media.date);
                    const year = date.getFullYear();
                    const month = date.getMonth() + 1;

                    const existingItem = timeline.find((t) => t.year === year && t.month === month);
                    if (existingItem) {
                        existingItem.media.push(media);
                    } else {
                        timeline.push({
                            year, month,
                            monthLabel: format(date, 'LLLL', { locale: fr }),
                            media: [media]
                        });
                    }
                }
            });

            timeline.sort((a, b) => {
                if (a.year > b.year) {
                    return -1;
                } else if (a.year < b.year) {
                    return 1;
                } else {
                    if (a.month > b.month) {
                        return -1;
                    } else if (a.month < b.month) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });

            return timeline;
        }
    }
};
</script>
